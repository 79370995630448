#page-document .blocks-group-wrapper > .block.listing {
    margin-top: 5rem;
}
.block.listing h2.headline {
    margin-top: 2.5em;
    margin-bottom: 2em;
}
.block.listing .listing-item, .column > .block.listing .listing-item, .block.search .listing-item, .block-editor-search .listing-item {
    border-bottom: 1px solid #b0b0b0;
}
.block.listing.grid .listing-item .card-container, .column > .block.listing.grid .listing-item .card-container, .block.search.grid .listing-item .card-container, .block-editor-search.grid .listing-item .card-container {
    background-color: #f7f7f7;
}
