#page-document .blocks-group-wrapper {
  .block.quote {
    .inner-wrapper {
      max-width: var(--default-container-width);
      margin-right: auto;
      margin-left: auto;
      blockquote {
        margin: 0 0;
      }
    }
  }
}



html {
    &[lang='de'] {
      .block.quote blockquote:not(.isEmpty) {
        p {
          &::before {
            content: '«';
          }
  
          &::after {
            content: '»';
          }
        }
      }
    }
  }