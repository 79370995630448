@import 'block-blockquote';
@import 'block-banner';
@import 'block-listing';

.footer-branding,
.footer-message,
.powered-by
{
  display: none;
}
.content-area {
  p > a,
  li > a {
    color: $zhkath-brown !important;
  }
}

// breadcrumbs
#main .breadcrumbs {
  background-color: transparent;
}
#main .breadcrumbs .breadcrumb .divider {
  transform: none;
  &::before {
    content: '–';
    font-size: 0.8em;
  }
}
#main .breadcrumbs .breadcrumb .home {
  margin: 0 7px 0 4px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  &::before {
    content: 'Startseite';
  }  
  svg {
    display: none;
  }
}

// footer
#footer .q.container.footer.layout {
  max-width: 100%;
  background-color: #2f2e5f;
  color: white;
  padding: 2.2rem 2rem;
}
